export default function config() {
  const ACCESS_ABILITY_SETTINGS_ORDERS = { action: 'change', subject: 'Catalog' }
  const tableColumns = [
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '50%', paddingLeft: '5%' },
    },
    {
      key: 'closed',
      label: 'Closed',
      thStyle: { width: '10%' },
    },
    {
      key: 'date',
      label: 'Date',
      thStyle: { width: '20%' },
    },
    {
      key: 'opens',
      label: 'Opens',
      thStyle: { width: '10%' },
    },
    {
      key: 'closes',
      label: 'Closes',
      thStyle: { width: '10%' },
    },
  ]

  return {
    tableColumns,
    ACCESS_ABILITY_SETTINGS_ORDERS,
  }
}
