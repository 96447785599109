<template>
  <div>
    <validation-observer
      ref="operationHoursVal"
    >
      <div>
        <table class="operation-hours-table">
          <thead>
            <tr>
              <th style="width: 130px;">
                Day
              </th>
              <th style="width: 200px;">
                Opens at
              </th>
              <th style="width: 200px;">
                Closes at
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style="height: 16px" />
            <tr
              v-for="(weekDay, index) in operationHours.operationHours"
              :key="index"
              class="pb-2"
            >
              <td class="d-flex">
                <div
                  class="mb-1"
                  style="position: relative; top: -4px"
                >
                  <span class="text-capitalize">{{ weekDay.day }}</span>
                  <div class="d-flex align-items-center">
                    <b-form-checkbox
                      v-model="weekDay.is_closed"
                      :disabled="isEdit"
                    />
                    <span style="position:relative; top: 3px">Closed</span>
                  </div>
                </div>
              </td>
              <td
                v-show="!weekDay.is_closed"
                style="vertical-align: top"
              >
                <div
                  v-for="(day, _index) in weekDay.times"
                  :key="_index"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Opens At')"
                    rules="required"
                    :vid="`hours_opens_at_${String(day.id)}`"
                  >
                    <div
                      class="d-inline-flex"
                      style="padding-right: 43px"
                    >
                      <LTimePickerInput
                        :id="`hours_opens_at_${String(day.id)}`"
                        v-model="day.opens_at"
                        :name="`hours_opens_at_${String(day.id)}`"
                        class="time-operation-hours"
                        :is-only-button="true"
                        :the-size="sizeOfIcon"
                        :field="{}"
                        :is-editable="isEdit"
                      />
                      <b-form-input
                        :id="`hours_text_${String(day.id)}`"
                        v-model="day.opens_at"
                        type="time"
                        style="height: 33px"
                        :disabled="isEdit"
                      />
                    </div>
                    <small
                      class="text-danger"
                      style="font-size: 14px"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </td>
              <td
                v-show="!weekDay.is_closed"
                style="vertical-align: top"
              >
                <div
                  v-for="(dayHours, _index) in weekDay.times"
                  :key="_index"
                  class="d-flex"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Closes At')"
                    :vid="`hours_${String(dayHours.id)}`"
                  >
                    <div class="d-inline-flex">
                      <LTimePickerInput
                        :id="`hours_${String(dayHours.id)}`"
                        v-model="dayHours.closes_at"
                        :name="`hours_${String(dayHours.id)}`"
                        class="time-operation-hours"
                        :is-only-button="true"
                        :the-size="sizeOfIcon"
                        :field="{}"
                        :is-editable="isEdit"
                      />
                      <b-form-input
                        :id="`hours_text_${String(dayHours.id)}`"
                        v-model="dayHours.closes_at"
                        type="time"
                        style="height: 33px"
                        :disabled="isEdit"
                      />
                    </div>
                    <small
                      class="text-danger"
                      style="font-size: 14px"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                  <b-button
                    v-show="dayHours.id === 1"
                    variant="light"
                    class="operation-hours-table-add-hours cursor-pointer border-dotted"
                    :disabled="isEdit"
                    @click="addNewHoursOperation(weekDay.day)"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                    />
                  </b-button>
                  <b-button
                    v-show="dayHours.id > 1"
                    variant="light"
                    class="operation-hours-table-add-hours cursor-pointer border-dotted"
                    @click="removeNewHoursOperation(weekDay.day, dayHours.id)"
                  >
                    <feather-icon
                      icon="MinusIcon"
                      size="16"
                    />
                  </b-button>
                </div>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
      <p class="font-weight-bolder font-medium-5">
        {{ $t('Special Hours(Holidays)') }}
      </p>
      <b-form-checkbox
        id="checkbox-1"
        class="mb-"
        style="margin-top: 20px"
        name="showPastDates"
        unchecked-value="not_accepted"
        :disabled="isEdit"
      >
        {{ $t('Show past dates') }}
      </b-form-checkbox>
      <l-table
        :create-page-url="{name: 'settings-orders-general-rules' }"
        :module-name="MODULE_NAME_OPERATION_HOURS"
        :table-columns="tableColumns"
        :is-searchable="false"
        :can-create="ACCESS_ABILITY_SETTINGS_ORDERS"
      />
    </validation-observer>
  </div>
</template>
<script>
import {
  BButton, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LTable from '@/views/components/LTable/LTable.vue'
import LTimePickerInput from '@/views/components/DynamicForm/components/LTimePickerInput.vue'
import { required } from '@/libs/validations/validations'
import config from '../generalRulesConfig'

export default {
  name: 'GeneralRulesForm',
  components: {
    BFormInput,
    LTable,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    LTimePickerInput,
    BButton,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      sizeOfIcon: 'sm',
    }
  },
  computed: {
    operationHours() {
      return this.$store.state[this.MODULE_NAME_OPERATION_HOURS].operationHourForm
    },
    operationHoursList() {
      return this.$store.state.listModule.operationHours
    },
    operationHoursFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].operationHoursFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.operationHours) !== this.operationHoursFormClone
    },
  },
  created() {
    this.$emit('updateMenu', 'settings-orders-general-rules-form')
    this.$emit('refetch')
  },
  methods: {
    // slicedOperationHours(operationHours, weekDay, id) {
    //   if (id > 1) {
    //     const chosenHour = weekDay.times[id - 2].closes_at
    //     if (chosenHour) {
    //       const timeFormat = chosenHour.split(' ')[1]
    //       const convertToNumericHour = (chosenHour.split(' ')[0].split(':').join('') / 100)
    //       return this.operationHoursList.filter(item => item.numericHour >= `${timeFormat === 'pm' ? (12 + convertToNumericHour) : convertToNumericHour}`)
    //     }
    //   }
    //   return this.operationHoursList
    // },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    loader() {
      this.$refs.operationHoursVal.reset()
      const smth = JSON.parse(this.operationHoursFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    addNewHoursOperation(weekDay) {
      const dayTimes = this.operationHours.operationHours[weekDay]
      dayTimes.times.push({
        id: dayTimes.times.length + 1,
        opens_at: dayTimes.times[dayTimes.times.length - 1].closes_at,
        closes_at: '',
      })
    },
    removeNewHoursOperation(weekDay, dayHoursId) {
      const dayTimes = this.operationHours.operationHours[weekDay]
      dayTimes.times = dayTimes.times.filter(item => item.id !== dayHoursId)
    },
    // removeHandler(data) {
    //   this.operationHours.closures = this.operationHours.closures.filter(item => item.id !== data.id)
    // },
    // getAfterChosenOperationHoursList(chosenHour) {
    //   if (chosenHour) {
    //     const timeFormat = chosenHour.split(' ')[1]
    //     const convertToNumericHour = (chosenHour.split(' ')[0].split(':').join('') / 100)
    //     return this.operationHoursList.filter(item => item.numericHour > `${timeFormat === 'pm' ? (12 + convertToNumericHour) : convertToNumericHour}`)
    //   }
    //   return this.operationHoursList
    // },
    // getNeedObj(arr, index) {
    //   return arr.find(item => item.id === index)
    // },
  },
  setup() {
    const MODULE_NAME_OPERATION_HOURS = 'settings-operation-hours'
    const MODULE_NAME_CLONE = 'cloneData'

    const { tableColumns, ACCESS_ABILITY_SETTINGS_ORDERS } = config()

    return {
      MODULE_NAME_OPERATION_HOURS,
      MODULE_NAME_CLONE,
      tableColumns,
      ACCESS_ABILITY_SETTINGS_ORDERS,
    }
  },
}
</script>
<style lang="scss" scoped>
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
.calendarInputs {
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.delete-btn {
  padding: 10px;
}
.customShadow:not(.bootstrap-touchspin):focus-within{
  box-shadow: none;
}
.calendar-icon-custom{
  z-index: 10;
  background-color: transparent;
  height: 30px;
  width: 18px;
  margin-top: 2px;
  margin-right: -25px;
}

.operation-hours-table {
  &__select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    text-indent: 1px !important;
    text-overflow: '' !important;
    background: none !important;
    width: 100px;
    height: 35px;
    padding: 4px;
    text-align: center;
  }

  &-add-hours {
    min-width: 35px;
    height: 35px;
    margin-left: 8px;
  }
  grayIcon {
    padding-top: 10px;
  }
  .time-operation-hours {
    display: flex;
    width: max-content;
  }
}
</style>
